.Training {
    padding: 50px 20px;
    background-color: #f8f8f8;
    text-align: center;
    margin-top: 2rem;
}

.training-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
}

.training-text{
    font-size: 1rem;
    color: #666;
}