/* Footer styles */
footer {
    background-color: #8f8585; /* Set the footer background color */
    color: #fff; /* Set the footer text color */
    padding: 20px;
    text-align: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-logo img {
    width: 200px; /* Adjust the width of your logo as needed */
    height: 200px;
    border-radius: 10px; /* Adjust the radius as needed */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 10px; /* Adjust the font size as needed */
}


.footer-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: start;
    justify-content: start;
    gap: 5px;
    line-height: 0.1rem;
}



.footer-social {
    margin-top: 20px;
}

.footer-social a {
    margin: 0 10px;
}

.footer-social img {
    width: 30px; /* Adjust the width of your social media icons as needed */
}


