.service {
    padding: 50px 20px;
    background-color: #f8f8f8;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

.service h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
}

.service p {
    font-size: 1rem;
    color: #666;
}
