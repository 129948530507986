.why-choose-us {
    padding: 30px 20px;
    background-color: #f8f8f8;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.why-choose-us h2 {
    font-size: 2.3rem;
    color: #333;
    margin-bottom: 30px;
}

.reasons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.reason {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    text-align: left;
}

.reason h3 {
    font-size: 1.5rem;
    color: #54b1ff;
    margin-bottom: 10px;
}

.reason p {
    font-size: 1rem;
    color: #666;
}
