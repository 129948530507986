.m-header {
    background-color: #8f8585;
    color: #54b1ff;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

}

.header-logo {
    height: 50px;
    width: 50px;
}

.m-header h1 {
    font-size: 21px;
    margin: 0;
}

.title-and-menu {
    display: flex;
    align-items: center;
}
.title-and-menu-link {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3d413c;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    text-align: center;
    transform: translateX(-120%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 1;
}

.nav-links.open {
    transform: translate(0, 0);
}

.m-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.m-header li {
    margin: 10px 0;
}

.m-header a {
    text-decoration: none;
    color: #ffffff;
    font-size: 17px;
    transition: color 0.3s ease;
}

.m-header a:hover {
    color: #54b1ff;
}

.action-button {
    background-color: #FF6800;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    margin-left: 2rem;
}

.menu-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    margin-left: 50px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-toggle.open .bar:first-child {
    transform: rotate(-45deg) translate(-11px, 13px);
}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 769px) {
    .action-button {
        margin-left: 0;
        margin-top: 2rem;
    }
}

@media screen and (min-width: 769px) {
    .m-header {
        flex-direction: row;
        align-items: center;

    }

    .menu-toggle {
        display: none;
    }

    .nav-links {
        position: static;
        transform: none;
        flex-direction: row;
        background-color: transparent;
        width: auto;
        height: auto;
        overflow-y: visible;
    }

    .m-header ul {
        flex-direction: row;
    }

    .m-header li {
        margin: 0 15px;
    }

    .m-header a {
        font-size: 16px;
    }
}

