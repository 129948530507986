.map-container {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 30rem; /* Set the height of the map container to 100% */
}

.map-container iframe {
    width: 100%;
}
