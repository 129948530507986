.Packages {
    /*background-color: rgb(252, 234, 246);*/
    text-align: center;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.package-title {
    font-size: 1.75rem;
}

.package-subtitle {
    font-size: 1.3rem;
}
.package-link {
    text-decoration: none;
}
.package-row-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.package-row-two {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

@media (max-width: 768px) {
    .package-row-one,
    .package-row-two {
        flex-direction: column;
    }
}

    .package-one {
        background-color: #a9f8ca;
        padding: 0.5rem;

    }

    .package-two {
        background-color: #b8e3f8;
        padding: 0.5rem;

    }

    .package-three {
        background-color: #f6eebb;
        padding: 0.5rem;

    }

    .package-custom {
        background-color: #f6bbbb;
        padding: 0.5rem;
    }


.li-package-features-{
    list-style: none;

}
.ul-package-feature {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 2rem;
    margin: -1.3rem 0 0;

}

/*
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    */

