.Gallery {
    text-align: center;
    padding: 20px;
}

h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
}

.gallery-item {
    border: 2px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
}

.gallery-item img {
    width: 100%;
    height: auto;
    display: block;

}
