/* HeroSection.css */

.hero-section {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-top: 100px;
    background: #f6aebc; /* Set your preferred background color */
    color: #fff; /* Set your preferred text color */
    padding: 20px 20px; /* Adjust the padding as needed */
    width: 80%;
}

.hero-section h1 {
    font-size: 3em; /* Adjust the font size as needed */
    margin-bottom: 0.3rem;
    margin-top: 1rem;
}

.hero-section p {
    font-size: 1.2em; /* Adjust the font size as needed */
    margin-bottom: 1.5rem;
}

.hero-section button {
    background: #fff; /* Set your preferred button background color */
    color: #3498db; /* Set your preferred button text color */
    padding: 10px 20px;
    font-size: 1em; /* Adjust the font size as needed */
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.hero-section button:hover {
    background: #2980b9; /* Set your preferred button background color on hover */
    color: #fff; /* Set your preferred button text color on hover */
}

.rondleiding-button {
    display: flex;
    align-items: center;
}
.rondleiding-icon {
    font-size: 1.5rem;
    margin-left: 0.5rem;
}
